@font-face {
  font-family: 'Fixedsys Excelsior 3.01';
  font-style: normal;
  font-weight: normal;
  src: local('Fixedsys Excelsior 3.01'), url('./static/fonts/FSEX300.woff') format('woff');
}

body {
  margin: 10px 100px;
  margin-bottom: 100px;
  font-family: 'Fixedsys Excelsior 3.01', 'Courier New', Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1b1b1b;
  color: #c0c0c0;
  text-align: center;
  font-size: 23px;
}

@media screen and (max-width: 992px) {
  body {
    margin: 10px 20px;
  }
}

video {
  object-fit: cover;
}

a {
  color: rgb(197, 245, 67);
}

p {
  line-height: 2rem;
}

mark {
  background-color: #78a36b;
  display: inline-block;
  padding: 0 5px;
  border: 1px solid #000;
}

mark:hover {
  animation: blink 1s infinite;
  animation-timing-function: linear;
}

.rainbow {
  background-image: linear-gradient(to left, violet, green, yellow, orange, red);
  -webkit-background-clip: text;
  color: transparent;
}

hr {
  background-color: #8f2a2a;
  border: 0;
  height: 30px;
  width: 30px;
  margin: 100px auto;
  transform: rotate(45deg);
}

hr.normal {
  background-color: rgb(83, 184, 83);
  border: 0;
  height: 20px;
  width: 100px;
  margin: 100px auto;
  transform: none;
}

hr:hover {
  animation: blink 1s infinite;
  animation-timing-function: linear;
}

@keyframes blink {
  0%, 40%, 60%{
    background-color: #3581cf;
  }
  80%, 90% {
    background-color: #78a36b;
    color: #000;
  }
  30%, 80% {
    background-color: #8f2a2a;
    color: #c0c0c0;
  }
}

.vertical-timeline-element-content {
  border-radius: 0 !important;
  box-shadow: none !important;
  color: #000;
  font-size: 20px;
  text-align: left;
}

.vertical-timeline-element-content h4 {
  margin: 0;
}

.vertical-timeline-element-icon {
  box-shadow: none !important;
}

.vertical-timeline-element-date {
  color: rgb(194, 194, 194);
}

.nft-gif {
  width: 400px;
  max-width: 100%;
  border: 5px solid #8f2a2a;
}

.icon {
  max-width: 100%;
  border: 5px solid #fff;
  border-radius: 100%;
}

#bg-video {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  padding: none;
  position: absolute;
  z-index: -1;
  pointer-events: none;
}


.wallet {
  background: rgba(0,0,0,.6);
  padding: 20px;
  width: 690px;
  margin: 0 auto;
  max-width: 90%;
}

img.align-right {
  float: right;
  margin: 10px;
}

img.align-left {
  float: left;
  margin: 10px;
}